import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import BlockRouter from '../components/BlockRouter';
import GradientWrapper from '../components/GradientWrapper';
import HelmetMichelberger from '../components/HelmetMichelberger';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import PageDescription from '../components/PageDescription';


const SharedPlaces = ({ data, location, pageContext }) => {
  const { languageSwitch } = pageContext;

  const footerData = data.allDatoCmsFooter.edges[0].node;
  const pageData = data.allDatoCmsSharedPlacesPage.edges[0].node;

  const { body, description, gradient, name, seoMetaTags } = pageData;

  return (
    <Layout languageSwitch={languageSwitch} location={location}>
      <GradientWrapper footerData={footerData} gradient={gradient} languageSwitch={languageSwitch}>
        <HelmetMichelberger seo={seoMetaTags} />
        <PageTitle>{name}</PageTitle>
        <PageDescription>{description}</PageDescription>
        <For each="block" index="index" of={body}>
          <BlockRouter key={block.id} block={block} index={index} />
        </For>
      </GradientWrapper>
    </Layout>
  );
};

SharedPlaces.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default SharedPlaces;

export const query = graphql`
  query SharedPlaces($locale: String!) {
    allDatoCmsSharedPlacesPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          gradient {
            color {
              hex
            }
          }
          name
          description
          ...GatsbyDatoCmsSharedPlacesPageBody
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;
